<template>
  <div>
    <touTop :headername="headerName" />
    <div class="reserve">
      <div class="left">
        <sider 
        :headername="headerName" 
        :sideMenus="sideMenu"
        @transkey="getKey"
         />
      </div>
      <!-- <div class="right" v-if="siderNumber === 0">
        <div>
          <breadCrumb :siderContent="siderName" />
        </div>
        <div>
          <a-tabs type="card" @change="clickTab">
            <a-tab-pane key="1" tab="桌台预定">
              <div>
                <a-tabs default-active-key="1" @change="cutover" :animated="false">
                  <a-tab-pane  :tab="item.storey_name" v-for="(item,index) in topList" :key="index"  >
                    <ul>
                      <li
                        v-for="(item, index) in items.data"
                        :key="index"
                      >
                        <div class="goods">
                          {{ item.table_name }}
                        </div>
                        <div class="h5">
                          <span v-if="judgeNumber(item.table_order_status)" class="activeColor">
                               {{ item.table_order_status }}
                          </span>
                          <span v-if="!judgeNumber(item&&item.table_order_status)">
                               {{ item.table_order_status }}
                          </span> 
                        </div>
                        <button class="myButton" @click="clickAdd(item.id)">
                          新增预定
                        </button>
                      </li>
                    </ul>
                    <div class="page" v-if="totals>0">
                      <a-pagination v-model="currents" :total="totals" @change="onChange" :defaultPageSize="30"/>
                    </div>
                    
                  </a-tab-pane>
                </a-tabs>
                <a-form-model
                  class="myForm"
                  :model="form"
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol"
                >
                  <a-form-model-item label="桌台">
                    <a-input
                      v-model="form.name"
                      placeholder="请输入要查询的桌台"
                      @change="onSearchTbale"
                    />
                  </a-form-model-item>
                </a-form-model>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="预定信息">
              <a-input-search
                placeholder="请输入姓名/手机号"
                style="width: 30vh"
                @search="onSearch"
                v-model="keywords"
                @pressEnter="onSearch"
              />
              <a-table
                :columns="columns"
                :data-source="members"
                :rowKey="
                  (record, index) => {
                    return index;
                  }
                "
                bordered
                style="width: 100%; margin-top: 1vh"
                @change="handleTableChange"
                 :pagination="{
                  current:current,
                  total:total,
                  pageSize:10,
                }"
              >
                <span slot="action" slot-scope="text, record" class="operate">
                  <span class="activeColor" v-if="record.is_founding === 1" @click="finsh(record.id && record.id )">
                     <span>完成预定</span>
                  </span>
                  <span  class="activeColor" v-if="record.is_cancel === 1" @click="cancel(record)">
                   <span>取消预订</span>
                  </span>
                  <span  v-if="record.is_founding === 0" class="jin">
                    <span>完成预定</span>
                     <span class="heng">————</span>
                  </span>
                  <span  v-if="record.is_cancel === 0" class="jin">
                    <span>取消预订</span>
                    <span class="heng">————</span>
                  </span>
                </span>
                <span slot="status" slot-scope="text" class="status">
                  <span v-if="text==='已取消'" class="can">{{text}}</span>
                  <span v-if="text==='已确认'" class="que">{{text}}</span>
                  <span v-if="text==='已完成'" class="finish">{{text}}</span>
                </span>
              </a-table>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div> -->
      <div class="content">
        <router-view/>
       <!-- <record v-if="siderNumber === 1" /> -->
      </div>
    </div>
    <!-- <addReserve :isShow="showAddREserve" :da="items&&items.data" @choose="changeList(arguments)" :tablename="curTablename"/> -->
    <!-- <cancel :isShow="showCancel" @choose="changeCancel(arguments)" :everyList="recordEvery"/> -->
  </div>
</template>
<script>
import sider from "@/components/sider/sider";
import touTop from "@/components/touTop/touTop";
import breadCrumb from "@/components/BreadCrumb/BreadCrumb";
// import addReserve from "./modules/addReserve";
// import cancel from "./modules/cancel";
// import record from "./modules/record";
// import {reserveInfo,reserveList} from '@/request/reserve.js';
// import { shopStorey } from "@/request/api";
// import { compare } from "@/public/common";
const columns = [
  {
    title: "桌台",
    dataIndex: "table_name",
    ellipsis: true,
  },
  {
    title: "姓名",
    dataIndex: "name",
    ellipsis: true,
  },
  {
    title: "手机号",
    dataIndex: "mobile",
    ellipsis: true,
  },
  {
    title: "就餐时间",
    dataIndex: "order_time",
    scopedSlots: { customRender: "way" },
  },
  {
    title: "人数",
    dataIndex: "people",
    ellipsis: true,
  },
  {
    title: "状态",
    dataIndex: "status",
    ellipsis: true,
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    dataIndex: "order_clerk",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  props: ["oneShow", "twoShow"],
  data() {
    return {
      siderNumber: 0,
      // columns,
      // members: [],
      // labelCol: { span: 4 },
      // wrapperCol: { span: 4 },
      // form: {
      //   name: "",
      // },
      // items: [],
      headerName: "预定系统",
      sideMenu: [
        { key: 0, value: "预定" },
        { key: 1, value: "预定信息" },
        { key: 2, value: "预定记录" },
      ],
      siderName: "预定系统", //sider切换内容
      // showAddREserve:false,//新增预定modal
      // curTablename:'',//点击新增当前的桌台名
      // current:1,//当前页
      // total:0,//数据条数
      // keywords:'',//输入框关键词
      // showCancel:false,//取消预定modal
      // recordEvery:'',//每条列表信息
      // currents:1,//桌台预定每条
      // storey_id:'',//区域
      // topList:[],//餐桌区域
    };
  },
  components: {
    touTop,
    sider,
    breadCrumb,
    // addReserve, //新增预定
    // cancel,//取消预定
    //  record,
  },
  mounted(){
    // this.reserveRequest();
    // this.tabList();
    // this.keywords="";
  },
  methods: {
    // onChange(current){ //桌台预定分页
    //   this.currents = current;
    //   this.reserveRequest()
    // },
    // cutover(index){ //点击区域
    //   this.storey_id=this.topList[index].store_id ?this.topList[index].store_id :'';
    //   this.currents=1;
    //   this.reserveRequest()
    // },
  
    // clickTab(key){ //点击桌台预定  和 预订信息
    //  console.log(key)
    //   if(key==='2'){
    //     this.current=1;
    //     this.messageRequest();
    //   }
    // },
    // onSearch() { //预订信息搜索
    //   this.messageRequest()
    // },
    // onSearchTbale(){
    //   this.reserveRequest()
    // },
    getKey(key) { //获取sider值
      if(key===0){
         this.$router.push({path: '/reserve/list',query: {key: key}})
      };
      if(key===1){
         this.$router.push({path: '/reserve/info',query: {key: key}})
      };
      if(key===2){
         this.$router.push({path: '/reserve/record',query: {key: key}})
      };
      this.siderNumber=key;
    },
    // reserveRequest(){//列表请求
    //   const _this=this;
    //    reserveInfo({
    //     is_order:1,
    //     listRows:30,
    //     page:_this.currents,
    //     storey_id:_this.storey_id,
    //     table_name:_this.form.name,
    //      })
    //     .then((res) => {
    //       if(res.data.status===200){
    //           _this.items=res.data.data.list && res.data.data.list;
    //           _this.totals=res.data.data.list.total && res.data.data.list.total;
    //         }               
    //       })
    //     .catch(result => {
    //         return false;
    //   })
    // },
    // judgeNumber(str){ //过滤数字改变字体颜色
    //   const a = /[0-9]/; 
    //   return a.test(str)
    // },
    // clickAdd(table){ //点击新增
    //   this.showAddREserve=true;
    //   this.curTablename=table;
    // },
    // changeList(number){//新增后更新列表
    //   if(number[0]){
    //     this.reserveRequest();
    //     this.showAddREserve=false;
    //   }
    //   if(number[1]){
    //     this.showAddREserve=false;
    //   }   
    // },
    // tabList() {  //餐桌列表
    //   shopStorey({
    //     page: 1,
    //     is_use: 1,
    //     // shop_id: this.shop_id,
    //   })
    //     .then((res) => {
    //       const topListold = res.data.data.list.data ? res.data.data.list.data : [];
    //       const topListNew = [];
    //       topListNew.push({ storey_name: "全部桌台", sort: 0, id: 0 });
    //       for (let i = 0; i < topListold.length; i++) {
    //         topListNew.push({
    //           storey_name: topListold[i].storey_name ? topListold[i].storey_name : "",
    //           sort: topListold[i].sort ? topListold[i].sort : "",
    //           id: topListold[i].id ? topListold[i].id : "",
    //           store_id:topListold[i].store_id ? topListold[i].store_id : "",
    //         });
    //       }
    //       this.topList = topListNew.sort(compare("sort"));
    //     })
    //     .catch((error) => {
    //       return false;
    //     });
    // },
  
    //  messageRequest(){//预定列表请求
    //   const _this=this;
    //    reserveList({
    //      listRows:10,
    //       page:_this.current,
    //       keywords:_this.keywords,
    //      })
    //     .then((res) => {
    //       if(res.data.status===200){
    //         _this.members=res.data.data.list.data && res.data.data.list.data;
    //         _this.total=res.data.data.list.total && res.data.data.list.total
    //         }               
    //       })
    //     .catch(result => {
    //         return false;
    //       })
    // },
    // handleTableChange(e){ //点击分页
    //    this.current = e.current;
    //    this.messageRequest()
    // },
    // cancel(record){ //取消预定
    //   this.showCancel=true;
    //   this.recordEvery=record;
    // },
    // changeCancel(number){  //关闭提示modal
    //   if(number[0]){
    //     this.showCancel=false;
    //   }
    //    if(number[1]){
    //     this.messageRequest();
    //   }  
    // },
    // finsh(id){   //点击完成预定
    //  this.$router.push({ path: '/yingye', query: { reserve_id: id } })
    // }
  },
};
</script>
<style lang="less" scoped>
.reserve {
  width: 100%;
  display: flex;
  height: 95.4vh;
  .left {
    width: 17vw;
    background: rgba(46, 62, 78, 1);
    display: flex;
  }
}
  /deep/.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    border-radius:0;
    border: 1px solid #1890ff;
    margin: 0;
    font-size: 1.8vh;
    width: 40vw;
    line-height: 3.6vh;
    height: 3.6vh;
    color: #1890ff;
    text-align: center;
  }
  /deep/.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background-color: #1890ff;
    border-radius:0;
    color: white;
    font-size: 1.8vh;
  }
  .content{
    background: rgba(248, 248, 255, 1);
  }
</style>